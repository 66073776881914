import React, { useMemo, useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby';
import FaqBlock from "../../molecules/Faq/FaqBlock"
import styles from './Faq.module.css'
import Container from "../../atoms/Container/Container";

const Faq = () => {

    const [retailList, setRetailList] = useState([]);
    const [activeList, setActiveList] = useState([]);

    const faqData = useStaticQuery(graphql`
        query {
            allBuilderModels {
                faqs(options: { cachebust: true }) {
                    name
                    data {
                        faqList
                    }
                }
            }
        }
    `);

    const [activeTabs, setActiveTabs] = useState({});

    const checkActiveTabs = (tabId) => {

        const newActiveTabs = activeTabs;
        if (tabId in newActiveTabs) {
            // Remove option
            delete newActiveTabs[tabId];
        } else {
            newActiveTabs[tabId] = true
        }
        
        setActiveTabs({...newActiveTabs});
    }

    useEffect(() => {
        setActiveList(retailList);
      }, [retailList])

    const faqList = (faqList) => {
        return ( 
            <div className={styles.faq}>
                <Container size="large">
                    <h6>FAQs</h6>

                    {faqList.map((item, id) => (
                        <FaqBlock key={id} faq={item} isOpen={(id in activeTabs) ? true : false} checkActiveTabs={ checkActiveTabs } id={id}/>
                    ))}
                </Container>
            </div>
        );
    }

    useMemo(() => {
        if (activeList.length === 0) {
            const faqs = faqData?.allBuilderModels.faqs;
            
            faqs.map(faqItems => {
                if (faqItems.name === 'FAQs') {
                  const retailFaqs = faqItems.data.faqList;
                  setRetailList(retailFaqs);
                }
                return true;
              });
        }
    }, [activeList, faqData]);

    return faqList(activeList);
}
 
export default Faq;