import React from 'react'

import Container from "../components/atoms/Container/Container"
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"

import Faq from '../components/organisms/FAQ/Faq'

const FaqPage = () => {
    return ( 
        <Layout>
            <SEO title="Faq" />
            <Container size="Large">
                <Faq />
            </Container>
        </Layout>
    )
}
 
export default FaqPage;