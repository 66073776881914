import React from 'react';
import styles from './FaqBlock.module.css';

const FaqBlock = ({ faq, isOpen, checkActiveTabs, id }) => {
  return (
    <div
      className={`${styles.faqBlock} ${isOpen && isOpen ? styles.isOpen : ''}`}
    >
      <div
        className={styles.faqTitle}
        onClick={() => checkActiveTabs(id)}
        role="presentation"
      >
        <span>{faq.faqTitle}</span>
        <span className={styles.faqBlockExpand} />
      </div>
      <div className={styles.faqBody}>
        <p>{faq.faqDescription}</p>
      </div>
    </div>
  );
};

export default FaqBlock;
